import React from 'react'
import styled from 'styled-components'
import { rem, is, flex, grid, position } from 'styled-tidy'
import theme from 'lib/styles/theme'
import ArtSize from 'components/art-size'
import Price from 'components/price'

const { colors, speeds } = theme
const { white, pearl, gray, silver, iron, ember, black } = colors

const List = styled.ol`
  ${grid(3, 16)}
  flex-wrap: wrap;
  list-style: none;
  user-select: none;
`

const Option = styled.label`
  ${flex('column', 'center', 'center')}
  background: ${white};
  box-shadow: inset 0 0 0 ${rem(2)} ${pearl};
  cursor: pointer;
  line-height: 1;
  padding: ${rem(12)} ${rem(4)};
  position: relative;
  transition: box-shadow ${speeds.quick}ms ease;

  :after {
    ${position('absolute', '50%', 0, 'auto')}
    background: ${pearl};
    content: '';
    height: ${rem(1)};
    transform: scaleX(0.6);
    transition: transform ${speeds.quick}ms ease;
  }

  span,
  small {
    color: ${gray};
  }

  svg {
    fill: ${gray};
  }

  span {
    font-weight: 200;

    :first-child {
      font-size: ${rem(18)};
      margin-bottom: ${rem(16)};
    }

    :last-child {
      font-size: ${rem(14)};
    }
  }

  :hover,
  :focus {
    box-shadow: inset 0 0 0 ${rem(2)} ${silver};

    :after {
      transform: scaleX(0.8);
    }
  }

  ${is('selected')`
    &,
    :hover,
    :focus {
      box-shadow: inset 0 0 0 ${rem(2)} ${iron};
      cursor: default;

      :after {
        transform: scaleX(0.6);
      }

      span:first-child {
        color: ${black};
      }

      svg {
        fill: ${black};
      }
    }
  `}
`

const ProductPriceSelector = ({ product, onChange, selectedSku }) => (
  <List>
    {product.prints.map(({ sku, size, price }) => (
      <li key={`price-price-${sku}`}>
        <Option selected={selectedSku === sku} onClick={() => onChange(sku)}>
          <ArtSize size={size} color={ember} />
          <Price cents={price} />
        </Option>
      </li>
    ))}
  </List>
)

export default ProductPriceSelector
