import React, { useState } from 'react'
import { inject } from 'mobx-react'
import { navigate } from 'gatsby'
import styled from 'styled-components'
import { rem, flex } from 'styled-tidy'
import cartConfig from 'config/cart'
import theme from 'lib/styles/theme'
import media from 'lib/styles/media'
import products from 'lib/products'
import Layout from 'layouts/default'
import Button from 'components/button'
import LeadForm from 'components/forms/lead-form'
import Line from 'components/line'
import ProductFigure from 'components/product-figure'
import ProductPriceSelector from 'components/product-price-selector'
import Paragraph from 'components/paragraph'
import Price from 'components/price'
import QuantityInput from 'components/quantity-input'
import Title from 'components/title'
import Wrap from 'components/wrap'

const { white } = theme.colors

const Article = styled.article`
  ${flex('column-reverse', 'flex-start', 'space-between')}
  padding: ${rem(16)} 0 ${rem(32)};
  width: 100% !important;

  ${Line} {
    display: none;
  }

  ${media.small`
    flex-direction: row-reverse;
    width: auto;

    ${Line} {
      display: block;
    }
  `}
`

const Content = styled.div`
  text-align: left;
  width: 100%;

  ${media.small`
    width: calc(50% - ${rem(16)});
  `}
`

const Sidebar = styled.div`
  background: ${white};
  width: 100%;

  ${media.small`
    max-width: ${rem(480)};
    width: calc(50% - ${rem(16)});
  `}

  img {
    height: auto;
    width: 100%;
  }
`

const PriceParagraph = styled(Paragraph)`
  font-size: ${rem(20)};
  font-weight: 400;
  margin: 0 0 ${rem(16)};
`

const PrintSizesWrapper = styled.ol`
  margin: 0 0 ${rem(16)};
  width: 100%;

  ${media.small`
    margin-bottom: ${rem(32)};
  `}
`

const QuanitityWrapper = styled.div`
  ${flex('row', 'center', 'flex-start')}

  > div {
    border-width: ${rem(1)};
  }

  > button {
    flex-grow: 1;
    margin-right: ${rem(12)};
  }

  ${media.medium`
    > button {
      flex-grow: 0;
      min-width: ${rem(149)};
    }
  `}
`

const Products = ({ cart: cartStore, location }) => {
  const productSlug = location.pathname.split(/\//g).pop()
  const product = products.find(p => p.slug === productSlug)
  const { sku, name, description, prints = [] } = product || {}
  const [quantity, setQuantity] = useState(1)

  // sort by lowest price
  prints.sort((a, b) => (a.price > b.price ? 1 : -1))

  const [selectedItem, setSelectedItem] = useState(
    prints.length
      ? cartStore.createItemFromProductSku(product, prints[0].sku, quantity)
      : {},
  )

  const handleSkuChange = sku => {
    setSelectedItem(cartStore.createItemFromProductSku(product, sku, quantity))
  }

  const handleQuantityChange = quantityString => {
    const quantity = parseInt(quantityString, 10)
    setQuantity(quantity)
    setSelectedItem({ ...selectedItem, quantity })
  }

  const addToCart = () => {
    cartStore.addItem(selectedItem)
  }

  if (!product) {
    if (global.window) navigate('/404')
    return null
  }
  return (
    <Layout path="/products">
      <Wrap>
        <Article>
          <Content>
            <Title is="h1">{name}</Title>
            <PriceParagraph>
              <Price cents={cartStore.getItemPrice(selectedItem)} />
            </PriceParagraph>
            {prints.length > 1 && (
              <PrintSizesWrapper>
                <ProductPriceSelector
                  product={product}
                  onChange={handleSkuChange}
                  selectedSku={selectedItem.sku}
                />
              </PrintSizesWrapper>
            )}
            <QuanitityWrapper>
              <Button is="button" kind="primary" onClick={addToCart}>
                Add to Cart
              </Button>
              <QuantityInput
                name={`product-quantity-${sku}`}
                min={1}
                max={cartConfig.maxItemQuantity}
                defaultValue={quantity}
                onChange={handleQuantityChange}
              />
            </QuanitityWrapper>
            <Line />
            {description && <Paragraph>{description}</Paragraph>}
          </Content>
          <Sidebar>
            <ProductFigure product={product} hideCaption />
          </Sidebar>
        </Article>
      </Wrap>
      <LeadForm />
    </Layout>
  )
}

export default inject('cart')(Products)
