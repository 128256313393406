import styled from 'styled-components'
import { rem } from 'styled-tidy'
import theme from 'lib/styles/theme'

const { smoke } = theme.colors

export default styled.div`
  border-top: ${rem(1)} solid ${smoke};
  margin: ${rem(16)} auto;
  width: 100%;
`
